import { Controller } from "stimulus";

const optionsTargetsArray = [
  "payInFullDiscount",
  "depositThenBalance",
  "customSchedule",
  "thirdParty"
];

const preferencesOptionsTargetsArray = [
  "patient_signature",
  "show_insurance_estimates",
  "qr_code"
]

const paymentOptionsTargetsArray = [
  "preferences",
  "paymentOptions",
  "merchantFee",
  ...optionsTargetsArray
];

export default class extends Controller {
  static targets = [
    "preferencesDescription",
    "paymentOptionsDescription",
    ...preferencesOptionsTargetsArray,
    ...generateTargets(paymentOptionsTargetsArray)
  ];

  connect() {
    optionsTargetsArray.forEach((option) => this.toggleOption(option))
    this.setPaymentOptionsDescription()
    this.setPreferencesDescription()
  }

  setPreferencesDescription() {
    let description = [];
    const preferencesDescription = this.preferencesDescriptionTarget;

    preferencesOptionsTargetsArray.forEach(option => {
      const checkbox = this.getTarget(option, "checkbox");
      const isChecked = checkbox ? checkbox.checked : false;
      if (isChecked) description.push(titleize(option))
    })
    preferencesDescription.innerHTML = description.join(', ')
  }

  setPaymentOptionsDescription() {
    const paymentOptionsDescription = this.paymentOptionsDescriptionTarget;
    let description = [];
    optionsTargetsArray.forEach(option => {
      const checkbox = this.getTarget(option, "checkbox");
      const paymentDescription = this.getTarget(option, "description");
      const isChecked = checkbox ? checkbox.checked : false;
      if (isChecked) description.push(descriptionByOption(option))
    })
    paymentOptionsDescription.innerHTML = description.join(', ')
  }

  toggleOption(option, event) {
    const checkbox = this.getTarget(option, "checkbox");
    const collapse = this.getTarget(option, "collapse");
    const button = this.getTarget(option, "collapseBtn");
    const description = this.getTarget(option, "description");
    const icon = this.getTarget(option, "icon");

    if (checkbox && event) $(checkbox).attr('checked', !checkbox.checked)
    const isChecked = checkbox ? checkbox.checked : false;

    if (isChecked) {
      icon.classList = 'fa fa-check text-teal fa-lg mt-1'
    } else {
      icon.classList = 'fa-regular fa-square fa-lg mt-1'
      $(collapse).collapse("hide");
    }

    if (collapse) {
      collapse.querySelectorAll('input').forEach(input => {
        if (collapse.id.includes("pay_in_full") && !isChecked) {
          if (input.id.includes("override_discount")) input.disabled = true;
        } else {
          input.disabled = !isChecked;
        }

        if (!collapse.id.includes("third_party")) {
          input.required = isChecked;
        }
      });
    }

    if (button) button.classList.toggle("d-none", !isChecked);
    if (description) description.classList.toggle("text-muted", !isChecked);

    this.setPaymentOptionsDescription();
    this.setPreferencesDescription();
  }

  payInFullDiscountToggle(event) {
    this.toggleOption('payInFullDiscount', event)
  }

  depositThenBalanceToggle(event) {
    this.toggleOption('depositThenBalance', event)
  }

  customScheduleToggle(event) {
    this.toggleOption('customSchedule', event)
  }

  thirdPartyToggle(event) {
    this.toggleOption('thirdParty', event)
  }

  patient_signatureToggle(event) {
    this.toggleOption('patient_signature', event)
  }

  show_insurance_estimatesToggle(event) {
    this.toggleOption('show_insurance_estimates', event)
  }

  qr_codeToggle(event) {
    this.toggleOption('qr_code', event)
  }



  getTarget(section, type) {
    return this.targets.find(`${section}${capitalize(type)}`);
  }
}

function generateTargets(sections) {
  const types = ["checkbox", "collapse", "collapseBtn", "icon", "description"];
  return sections.flatMap(section => types.map(type => `${section}${capitalize(type)}`));
}

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

function titleize(str) {
  return str
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

function descriptionByOption(option) {
  switch (option) {
    case 'payInFullDiscount':
      return 'Pay-in full discount'
      break;
    case 'depositThenBalance':
      return 'Deposit then Balance'
      break;
    case 'customSchedule':
      return 'Payment Plan'
      break;
    case 'thirdParty':
      return 'Financing'
      break;
    default:
      'None'
      break;
  }
}
